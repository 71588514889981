import {
    deleteWithCredentials,
    getWithCredentials,
    postWithCredentials,
    sendPostbackForm
} from './fetchHelpers';
import {
    create,
    get, parseCreationOptionsFromJSON,
    parseRequestOptionsFromJSON,
    supported
} from '@github/webauthn-json/browser-ponyfill';
import {writable, get as getStore} from "svelte/store";
import {wait} from "./utils.js";

const REGISTRATION_CHALLENGE_URL = () => `/aid/profil/registrer_webauthn`;
const REGISTER_NEW_DEVICE_URL = () => '/aid/profil/registrer_webauthn';

const LOGIN_CHALLENGE_URL = `/aid/logg_inn/start_webauthn${location.search}`;
const AUTO_LOGIN_CHALLENGE_URL = `/aid/logg_inn/start_webauthn${location.search}&mediation=conditional`;
const ASSERT_LOGIN_CHALLENGE_URL = () => {
    let pubMatch = /logg_inn\/([^./]+\.[^./]+\.[^./]+)/.exec(location.pathname);
    return globalThis.vesta?.webauthnUrls.assertLogin || `/aid/logg_inn${pubMatch ? "/"+pubMatch[1] : ""}/verifiser_webauthn${location.search}`;
}
const SUDOMODE_CHALLENGE_URL = "/aid/logg_inn/verifiser/passordfri";
const ASSERT_SUDOMODE_CHALLENGE_URL = () => globalThis.vesta?.webauthnUrls.assertSudomode || `/aid/logg_inn/verifiser/passordfri${location.search}`;

const GET_CREDENTIALS_URL = () => `/aid/profil/hent_webauthn_nokler`;
const DELETE_CREDENTIAL_URL = (keyId) => `/aid/profil/slett_webauthn?key_id=${encodeURIComponent(keyId)}`;
const abortController = writable(null);
const currentLoginChallenge = writable(null);

/**
 * @returns {Promise<{
 *     challenge_uuid: string,
 *     registration: any
 * }>}
 */
const fetchRegistrationChallenge = () => getWithCredentials(
    REGISTRATION_CHALLENGE_URL())
    .then(response => response.ok
        ? response.json()
        : Promise.reject(`Failed requesting register challenge for user`));

/**
 * Return the signed challenge for validation and saving for future logins.
 * @param challengeUuid {string}
 * @param registrationResponse {any} This should really be a AuthenticatorAttestationResponse in json format
 * @param nick {string}
 * @returns {Promise<Response>}
 */
const registerNewDevice = (challengeUuid, registrationResponse, nick) =>
    postWithCredentials(REGISTER_NEW_DEVICE_URL(), {
        challenge_uuid: challengeUuid,
        registration_response: registrationResponse,
        nick
    })
        .then(response => response.ok
            ? Promise.resolve()
            : Promise.reject(`Kunne ikke registrere ny enhet.`));

/**
 * Fetch auto login challenge
 * Fetch challenge for autocomplete login (mediation=conditional)
 * @returns {Promise<{
 *     request_id: string,
 *     public_key_credential_request_options: any
 * }>}
 */
const fetchAutoLoginChallenge = (err = null) =>
    getWithCredentials(AUTO_LOGIN_CHALLENGE_URL + (err ? '&error=' + encodeURIComponent(err) : ''))
        .then(response => response.ok
            ? response.json()
            : Promise.reject(`Failed requesting login challenge for user`));

/**
 * Fetch login challenge
 * This may fail if the user has no stored credentials to sign
 * @todo ^- Handle said failure state
 * @returns {Promise<{
 *     username: string,
 *     request_id: string,
 *     public_key_credential_request_options: any
 * }>}
 */
const fetchLoginChallenge = () =>
    getWithCredentials(LOGIN_CHALLENGE_URL)
        .then(response => response.ok
            ? response.json()
            : Promise.reject(`Failed requesting login challenge for user`));

/**
 * Fetch sudomode challenge
 * This may fail if the user has no stored credentials to sign
 * @todo ^- Handle said failure state
 * @returns {Promise<{
 *     username: string,
 *     request_id: string,
 *     public_key_credential_request_options: any
 * }>}
 */
const fetchSudomodeChallenge = () =>
    getWithCredentials(SUDOMODE_CHALLENGE_URL)
        .then(response => response.ok
            ? response.json()
            : Promise.reject(`Failed requesting sudomode challenge for user`));

export const isSupported = async () => {
    if (!supported()) {
        return false;
    } else if (/iPad|iPhone|iPod/i.test(navigator.userAgent) && !/Safari/i.test(navigator.userAgent)) {
        /**
         * ^- Matching:
         * - Mozilla/5.0 (iPhone; CPU iPhone OS 14_8_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) pushvarslings-app/3.4.33
         * - Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 [FBAN/FBIOS;FBDV/iPhone12,1;FBMD/iPhone;FBSN/iOS;FBSV/16.3.1;FBSS/2;FBID/phone;FBLC/nb_NO;FBOP/5]
         * - Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 eAvis
         */
        return false;
    }

    // Check for CTAP2 support if possible. This blocks Webauthn in Firefox until
    // it supports Usernameless Webauthn (residentKey).
    if (window.PublicKeyCredential?.isExternalCTAP2SecurityKeySupported) {
        return await window.PublicKeyCredential.isExternalCTAP2SecurityKeySupported();
    }

    return true;
}

export const performRegistrationCeremony = async (nick) => {
    const { challenge_uuid, registration } = await fetchRegistrationChallenge();
    const timeBeforeCreate = performance.now();
    let response;
    try {
        response = await create(parseCreationOptionsFromJSON(registration));
    } catch (err) {
        const timeOfException = performance.now();
        handleWebauthnRegistrationError(err, timeOfException - timeBeforeCreate);
    }
    return registerNewDevice(challenge_uuid, JSON.stringify(response), nick)
        .then((r) => {
            prioritizeWebauthn()
            return r;
        })
}

async function mediationAvailable() {
    const pubKeyCred = PublicKeyCredential;
    return typeof pubKeyCred.isConditionalMediationAvailable === "function" && await pubKeyCred.isConditionalMediationAvailable();
}

export const performAutoLoginCeremony = async (postbackErrorUrl = null, err = null, currentAttempt = 1) => {
    if (!(await mediationAvailable())) {
        return;
    }
    if (currentAttempt > 5) {
        console.warn("Too many failed webauthn auto login attempts. Giving up.");
        return;
    }

    const abort = new AbortController();
    abortController.set(abort);

    if (getStore(currentLoginChallenge)) {
        return;
    }
    try {
        const { challenge_uuid, assertion } = await fetchAutoLoginChallenge(err);
        const response = await get({
            ...parseRequestOptionsFromJSON(assertion),
            signal: abort.signal
        })
        prioritizeWebauthn()
        sendPostbackForm(ASSERT_LOGIN_CHALLENGE_URL(), {
            challenge_uuid,
            assertion_response: JSON.stringify(response),
        })
    } catch (err) {
        console.error(err);
        await wait(currentAttempt * 1000);
        performAutoLoginCeremony(postbackErrorUrl, err, currentAttempt + 1);
    }
}

export const performLoginCeremony = async (postbackErrorUrl = null) => {
    currentLoginChallenge.set('starting');
    const abort = getStore(abortController);
    if (abort) {
        abort.abort();
    }
    try {
        const { challenge_uuid, assertion } = await fetchLoginChallenge();
        currentLoginChallenge.set(challenge_uuid);
        const response = await get(parseRequestOptionsFromJSON(assertion))
        prioritizeWebauthn()
        sendPostbackForm(ASSERT_LOGIN_CHALLENGE_URL(), {
            challenge_uuid,
            assertion_response: JSON.stringify(response),
        })
        currentLoginChallenge.set(null);
    } catch (err) {
        handleWebauthnClientError(err, postbackErrorUrl || ASSERT_LOGIN_CHALLENGE_URL());
        currentLoginChallenge.set(null);
        performAutoLoginCeremony(postbackErrorUrl);
    }
}

export const performWebAuthnSudomodeCeremony = async () => {
    try {
        const { challenge_uuid, assertion } = await fetchSudomodeChallenge();
        const response = await get(parseRequestOptionsFromJSON(assertion))
        prioritizeWebauthn()
        sendPostbackForm(ASSERT_SUDOMODE_CHALLENGE_URL(), {
            challenge_uuid,
            assertion_response: JSON.stringify(response),
        });
    } catch (err) {
        handleWebauthnClientError(err, ASSERT_SUDOMODE_CHALLENGE_URL());
    }
}

export class AbortedError extends Error {
}

export class ExcludedError extends Error {
}

const handleWebauthnRegistrationError = (err, msToError) => {
    if (msToError < 3000) {
        prioritizeWebauthn(); // Looks like we have a key, so we should prioritize Webauthn on next login.
        throw new ExcludedError();
    } else {
        throw new AbortedError();
    }
}

const handleWebauthnClientError = (err, challengeUrl) => {
    console.error(err);
    doNotPrioritizeWebauthn();
    if (err instanceof DOMException && err.name === "NotSupportedError") {
        return sendWebauthnError("usernameless_login_not_supported", challengeUrl);
    } else if (err instanceof DOMException) {
        return sendWebauthnError("aborted", challengeUrl);
    } else {
        return sendWebauthnError("unknown", challengeUrl);
    }
}

const sendWebauthnError = (err, challengeUrl) => {
    sendPostbackForm(challengeUrl, {
        webauthn_error: err
    })
}

export const deleteCredential = (keyId) => deleteWithCredentials(DELETE_CREDENTIAL_URL(keyId));

export const loadCredentials = () => getWithCredentials(GET_CREDENTIALS_URL())
    .then(response => {
        if(!response.ok) {
            return Promise.reject("Kunne ikke hente nøkler. Du kan forsøke å laste siden på nytt.")
        }
        return response.json();
    })
    .then((json) => {
        (Array.isArray(json) && json.length === 0) && doNotPrioritizeWebauthn();
        return json;
    })

const prioritizeWebauthnLocalStorageKey = "prioritize_webauthn";
const prioritizeWebauthn = () => localStorage.setItem(prioritizeWebauthnLocalStorageKey, "true");
export const doNotPrioritizeWebauthn = () => localStorage.removeItem(prioritizeWebauthnLocalStorageKey);
export const shouldPrioritizeWebauthn = async () => {
    try {
        return await isSupported() && localStorage.getItem(prioritizeWebauthnLocalStorageKey)?.toLowerCase() === 'true';
    } catch (e) {
        console.error(e);
        return false;
    }
}
