<script>
    import Button from "~/components/Button.svelte";
    import WebauthnLogo from "~/components/webauthn/WebauthnLogo.svelte";

    export let prio = false;
    export let logName = 'webauthn';
    export let href = undefined;
</script>

<Button {prio} {href} borderless={true} {logName} on:click>
    <div class="button_content {prio ? 'inverted' : ''}">
        <WebauthnLogo inverted={true} --svg-square-size="35px" />
        <span><slot /></span>
    </div>
</Button>

<style>
    @import "../../common/colors.css";

    .button_content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 10px;
        width: 100%;
    }
</style>